import cartApi from '@/services/api/cart'
import itemsApi from '@/services/api/items'
import replacementGroupsApi from '@/services/api/replacement-groups'
import { tomorrow } from '@/services/helpers'
import {
  isEmpty,
  debounce,
  orderBy,
  omit,
  flatten,
  values,
  keys,
  pickBy,
} from 'lodash'

const debounceDelay = 500

export const namespaced = true

export const state = {
  cart_templates: [],
  currentViewersList: [],
  selected_sub_order: null,
  submittingOrderId: null,
  loading: false,
  loadingId: +new Date(),
  verifying: false,
  current_cart: {
    applied_cart_templates: [],
    cart_name: null,
    description: null,
    number: null,
    orders: [],
    template_items_sum: [],
    total_sub_orders_count: 0,
    subOrdersForm: [],
    meta: {
      available_sub_orders_count: 0,
      shipping_costs: 0,
      total_amount: 0,
      total_items_count: 0,
      value_of_costs: 0,
      vat_rate: 0,
    },
  },
  filter: {
    value: null,
    options: [],
  },
  isCheckoutLoading: false,
  isCartChanging: false,
  list: [],
  optimization: {
    applying: false,
    creating: false,
    loading: false,
    list: [],
    cart_optimization_rule: null,
    optimization_rules: [],
    optimization_rule_types: [],
    cart_statistics: {
      best_price_amount: 0,
      best_price_quantity: 0,
      organic_amount: 0,
      organic_quantity: 0,
      regional_amount: 0,
      regional_quantity: 0,
      total_amount: 0,
      total_quantity: 0,
      total_volume: 0,
      optimized_best_price_amount: 0,
      optimized_best_price_quantity: 0,
      optimized_organic_amount: 0,
      optimized_organic_quantity: 0,
      optimized_regional_amount: 0,
      optimized_regional_quantity: 0,
      optimized_total_amount: 0,
      optimized_total_quantity: 0,
      optimized_total_volume: 0,
    },
    optimized_master_order: null,
    before_optimization_exclusions: {},
    exclusions: {},
  },
}

export const mutations = {
  ADD_CART(state, data) {
    state.list = [...state.list, data]
  },
  ADD_CART_OPTIMIZATION_EXCLUSIONS(state, data) {
    state.optimization.exclusions = {
      ...state.optimization.exclusions,
      ...data,
    }
  },
  BUILD_LINE_ITEM(_ctx, { item, currentCart }) {
    currentCart.line_items.push({
      id: item.id,
      quantity: 1,
      supplier_tenant_id: item.supplier_tenant_id,
      variant_id: item.variant_id,
    })
  },
  REMOVE_CART(state, { id }) {
    state.list = state.list.filter((cart) => cart.id !== id)
  },
  REMOVE_CART_OPTIMIZATION_EXCLUSIONS(state, key) {
    state.optimization.exclusions = omit(state.optimization.exclusions, key)
  },
  SET_BEFORE_OPTIMIZATION_EXCLUSIONS(state, data) {
    state.optimization.before_optimization_exclusions = data
  },
  SET_OPTIMIZATION_EXCLUSIONS(state, data) {
    state.optimization.exclusions = data
  },
  SET_CURRENT_CART_VIEWERS(state, { current_users }) {
    state.currentViewersList = current_users
  },
  SET_CART(state, masterOrder) {
    state.list = state.list.map((cart) =>
      cart.id === masterOrder.id ? masterOrder : cart
    )
  },
  SET_CART_CHANGING(state, value) {
    state.isCartChanging = value
  },
  SET_CARTS(state, { master_orders }) {
    state.list = master_orders
  },
  SET_CART_TEMPLATES(state, data) {
    state.cart_templates = data.templates
  },
  SET_CART_OPTIMIZATION(state, { optimization_groups }) {
    state.optimization.list = optimization_groups
  },
  SET_CART_OPTIMIZATION_RULE(state, { cart_optimization_rule }) {
    state.optimization.cart_optimization_rule = cart_optimization_rule
  },
  SET_CART_OPTIMIZATION_RULES(
    state,
    { optimization_rules, optimization_rule_types }
  ) {
    state.optimization.optimization_rules = optimization_rules
    state.optimization.optimization_rule_types = optimization_rule_types
  },
  SET_CART_OPTIMIZATION_STATISTICS(state, { cart_statistics }) {
    state.optimization.cart_statistics = cart_statistics
  },
  SET_CART_OPTIMIZATION_APPLYING(state, value) {
    state.optimization.applying = value
  },
  SET_CART_OPTIMIZATION_LOADING(state, value) {
    state.optimization.loading = value
  },
  SET_CART_OPTIMIZATION_CREATING(state, value) {
    state.optimization.creating = value
  },
  SET_CART_OPTIMIZATION_GROUP(state, replacement_group) {
    state.optimization.list = state.optimization.list.map((group) => {
      if (group.replacement_group_id !== replacement_group.replacement_group_id)
        return group

      return { ...group, ...replacement_group }
    })
  },
  SET_CART_OPTIMIZATION_GROUP_ITEMS(state, { replacement_group }) {
    state.optimization.list = state.optimization.list.map((group) => {
      if (group.replacement_group_id !== replacement_group.id) return group

      group.items = group.items.map((item) => {
        const updatedItem = replacement_group.items.find(
          (rg_item) => rg_item.id === item.variant_id
        )

        return { ...item, ...updatedItem }
      })

      return group
    })
  },
  SET_CART_OPTIMIZATION_GROUP_ITEM(state, { item }) {
    state.optimization.list = state.optimization.list.map((group) => {
      group.items = group.items.map((oldItem) => {
        if (oldItem.variant_id !== item.variant_id) return oldItem

        return { ...oldItem, ...item }
      })

      return group
    })
  },
  SET_CHECKOUT_LOADING(state, value) {
    state.isCheckoutLoading = value
  },
  SELECT_SUB_ORDER(state, subOrder) {
    state.selected_sub_order = subOrder
  },
  SET_CART_LOADING(state, value) {
    state.loading = value
    state.loadingId += 1
  },
  SET_CART_VERIFYING(state, value) {
    state.verifying = value
  },
  SET_CURRENT_CART_META(
    state,
    {
      available_sub_orders_count,
      shipping_costs,
      total_amount,
      total_items_count,
      value_of_costs,
      vat_rate,
      total,
    }
  ) {
    state.current_cart.meta.available_sub_orders_count =
      available_sub_orders_count
    state.current_cart.meta.shipping_costs = shipping_costs
    state.current_cart.meta.total_amount = total_amount
    state.current_cart.meta.total_items_count = total_items_count
    state.current_cart.meta.value_of_costs = value_of_costs
    state.current_cart.meta.vat_rate = vat_rate
    state.current_cart.meta.shipping_costs = shipping_costs
    state.current_cart.meta.total = total
  },
  SET_OPTIMIZED_MASTER_ORDER(state, master_order) {
    state.optimization.optimized_master_order = {
      ...master_order,
    }
  },
  SET_SUBMITTING_ORDER_ID(state, id) {
    state.submittingOrderId = id
  },
  UPDATE_SHIPPING_COSTS(state, { order }) {
    if (!state.selected_sub_order) return

    state.selected_sub_order.shipping_costs = order.shipping_costs
    state.selected_sub_order.total = order.total
  },
}

const debouncedUpdateMealsQuantity = debounce(
  async (commit, _getters, order_template_id, meals_quantity) => {
    const response = await cartApi.templates.master_orders.update({
      order_template_id,
      meals_quantity,
    })

    commit('SET_CURRENT_CART_META', response.data.meta)
  },
  debounceDelay
)

export const actions = {
  async addToCart({ commit }, { id, amount, supplier_tenant_id }) {
    const response = await cartApi.masterOrders.create({
      product_id: id,
      quantity: amount,
      supplier_tenant_id,
    })

    commit('currentUser/SET_CURRENT_USER_CART', response.data, {
      root: true,
    })

    return response
  },
  buildLineItem({ commit, getters }, item) {
    commit('BUILD_LINE_ITEM', { item, currentCart: getters.currentCart })
  },
  async checkoutMasterOrder(
    { commit, getters },
    {
      description,
      save_as_template,
      template_name,
      template_description,
      meals_quantity,
    }
  ) {
    const response = await cartApi.masterOrders.update({
      sub_orders: getters.currentCart.orders,
      description,
      save_as_template,
      template_name: template_name?.name,
      template_description: template_description,
      meals_quantity,
    })

    commit('currentUser/SET_CURRENT_USER_CART', response.data, { root: true })
  },
  async checkoutSubOrder(
    { commit },
    {
      id,
      description,
      deliver_at,
      save_as_template,
      template_name,
      template_description,
      meals_quantity,
    }
  ) {
    const response = await cartApi.orders.update(id, {
      comment: description,
      deliver_at,
      save_as_template,
      template_name: template_name?.name,
      template_description: template_description,
      meals_quantity,
    })

    commit('currentUser/SET_CURRENT_USER_CART', response.data, { root: true })
    return response.data
  },
  async clearCartOptimization({ commit, dispatch }) {
    await dispatch('clearOptimizedMasterOrder')
    commit('SET_OPTIMIZATION_EXCLUSIONS', {})
  },
  async createCart({ commit }, params) {
    const response = await cartApi.create(params)

    commit('ADD_CART', response.data)
  },
  async createOptimizationRule(_ctx, params) {
    return await cartApi.optimization.rules.create(params)
  },
  async deleteCart({ commit }, id) {
    const response = await cartApi.delete(id)

    commit('REMOVE_CART', response.data)
  },
  async deleteOptimizationRule({ commit, getters }, id) {
    const response = await cartApi.optimization.rules.delete(id)

    if (getters.cartOptimizationRule.id === id) {
      commit('SET_CART_OPTIMIZATION_RULE', response.data)
    }
  },
  async deleteSubOrder({ commit }, id) {
    const response = await cartApi.orders.delete(id)

    commit('currentUser/SET_CURRENT_USER_CART', response.data, {
      root: true,
    })
  },
  async deleteItem({ commit }, { id, ...params }) {
    const response = await cartApi.lineItems.delete(id, params)

    commit('currentUser/SET_CURRENT_USER_CART', response.data, { root: true })
  },
  async getCart({ state, commit, dispatch }, params) {
    commit('SET_CART_LOADING', true)
    const startLoadingId = state.loadingId

    try {
      const response = await cartApi.show(params)

      const endLoadingId = state.loadingId

      if (startLoadingId !== endLoadingId) return

      dispatch('currentUser/setCurrentCart', response.data, { root: true })
    } catch (_error) {
      dispatch('currentUser/clearCurrentCart', null, { root: true })
    }
    commit('SET_CART_LOADING', false)
  },
  async getCarts({ commit }) {
    const response = await cartApi.index()

    commit('SET_CARTS', response.data)
  },
  async getCartOptimization({ state, commit }) {
    commit('SET_CART_OPTIMIZATION_LOADING', true)
    const optimizedMasterOrder = state.optimization.optimized_master_order
    const params = optimizedMasterOrder
      ? { optimized_master_order_id: optimizedMasterOrder.id }
      : {}
    const response = await cartApi.optimization.show(params)

    commit('SET_CART_OPTIMIZATION', response.data)
    commit('SET_CART_OPTIMIZATION_RULE', response.data)
    commit('SET_CART_OPTIMIZATION_STATISTICS', response.data)

    commit('SET_CART_OPTIMIZATION_LOADING', false)
  },
  async getCartOptimizationGroup({ commit }, replacement_group_id) {
    const optimizedMasterOrder = state.optimization.optimized_master_order
    const params = optimizedMasterOrder
      ? { optimized_master_order_id: optimizedMasterOrder.id }
      : {}

    const response = await cartApi.optimization.replacement_groups.show(
      replacement_group_id,
      params
    )

    commit('SET_CART_OPTIMIZATION_GROUP', response.data)
    commit('SET_CART_OPTIMIZATION_STATISTICS', response.data)
  },
  async getCartOptimizationGroupShort({ commit }, replacement_group_id) {
    const response = await cartApi.optimization.replacement_groups.short.show(
      replacement_group_id
    )

    commit('SET_CART_OPTIMIZATION_GROUP', response.data)
    commit('SET_CART_OPTIMIZATION_STATISTICS', response.data)
  },
  async getCartOptimizationRules({ commit }) {
    const response = await cartApi.optimization.rules.index()

    commit('SET_CART_OPTIMIZATION_RULES', response.data)
  },
  async getCartTemplates({ commit }) {
    const response = await cartApi.templates.index({ all: true })

    commit('SET_CART_TEMPLATES', response.data)
  },
  async getDeliveryDays(_ctx, order_id) {
    const response = await cartApi.orders.delivery_days.index(order_id)

    return response
  },
  setCartLoading({ commit }, value) {
    commit('SET_CART_LOADING', value)
  },
  setCartChanging({ commit }, value) {
    commit('SET_CART_CHANGING', value)
  },
  setCartOptimizationGroupExclusions({ commit }, { groupId, value }) {
    if (isEmpty(value)) {
      commit('REMOVE_CART_OPTIMIZATION_EXCLUSIONS', groupId)
    } else {
      commit('ADD_CART_OPTIMIZATION_EXCLUSIONS', { [groupId]: value })
    }
  },
  setCheckoutLoading({ commit }, value) {
    commit('SET_CHECKOUT_LOADING', value)
  },
  setOrderFormDeliverAt({ commit }, date) {
    commit('SET_ORDER_FORM_DATE', date)
  },
  setSubmittingOrderId({ commit }, id) {
    commit('SET_SUBMITTING_ORDER_ID', id)
  },
  async updateCart({ commit }, { name, id }) {
    const { data } = await cartApi.update(id, { name })

    commit('SET_CART', data)
    commit('currentUser/SET_CURRENT_USER_CART_NAME', data.cart_name, {
      root: true,
    })
  },
  async updateOptimization({ commit }, id) {
    const response = await cartApi.optimization.update({ rule_id: id })

    commit('SET_CART_OPTIMIZATION_RULE', response.data)
  },
  async updateOptimizationRule({ commit, getters }, { id, ...params }) {
    const { data } = await cartApi.optimization.rules.update(id, params)

    if (getters.cartOptimizationRule.id === id) {
      commit('SET_CART_OPTIMIZATION_RULE', { cart_optimization_rule: data })
    }
  },
  async updateOptimizationType({ commit }, { optimizationType }) {
    const { data } = await cartApi.optimization_type.update({
      optimization_type: optimizationType,
    })

    commit('currentUser/UPDATE_CURRENT_CART_OPTIMIZATION_TYPE', data, {
      root: true,
    })
  },
  async removeTemplate({ commit }, id) {
    const response = await cartApi.templates.master_orders.delete(id)

    commit('SET_CURRENT_CART_META', response.data.meta)
  },
  selectSubOrder({ commit }, subOrder) {
    commit('SELECT_SUB_ORDER', subOrder)
  },
  updateCurrentViewersList({ commit }, { current_users }) {
    commit('SET_CURRENT_CART_VIEWERS', current_users)
  },
  async updateCurrentCart({ commit }, { id }) {
    const response = await cartApi.update(id, { current: true })

    commit('currentUser/SET_CURRENT_USER_CART', response.data, {
      root: true,
    })
  },
  updateLineItem({ commit }, { item }) {
    commit(
      'currentUser/SET_CURRENT_USER_CART_ITEM',
      { item },
      {
        root: true,
      }
    )
  },
  async updateLineItemTotalPrice({ commit }, { id, price }) {
    const response = await cartApi.lineItems.total_prices.update(id, {
      price: price,
    })

    commit('currentUser/SET_CURRENT_USER_CART', response.data, { root: true })
  },
  async updateLineItemUnitPrice({ commit }, { id, price }) {
    const response = await cartApi.lineItems.unit_prices.update(id, {
      price: price,
    })

    commit('currentUser/SET_CURRENT_USER_CART', response.data, { root: true })
  },
  async updateQuantity({ commit }, { id, quantity, syncCart = true }) {
    const response = await cartApi.lineItems.update(id, {
      quantity: quantity,
    })

    if (syncCart) {
      commit('currentUser/SET_CURRENT_USER_CART', response.data, {
        root: true,
      })
    }
  },
  updateMealsQuantity(
    { commit, getters },
    { order_template_id, meals_quantity }
  ) {
    if (meals_quantity !== '') {
      debouncedUpdateMealsQuantity(
        commit,
        getters,
        order_template_id,
        meals_quantity
      )
    }
  },
  async updateOrderDeliverAt(_ctx, params) {
    await cartApi.orders.delivery_date.update(params)
  },
  async updateOrderShippingCosts({ commit }, { order_id, shipping_costs }) {
    const response = await cartApi.orders.shipping_costs.update(order_id, {
      shipping_costs,
    })

    commit('UPDATE_SHIPPING_COSTS', response.data)
  },
  async externalSync() {
    const response = await cartApi.externalSync()
    return response.data
  },
  async verifyCartUnits({ commit, dispatch }, orderId) {
    commit('SET_CART_VERIFYING', true)

    try {
      const response = await cartApi.verify_units.create({
        order_ids: [orderId],
      })

      dispatch('currentUser/setCurrentCart', response.data, { root: true })
    } catch (_error) {
      dispatch('currentUser/clearCurrentCart', null, { root: true })
    }

    commit('SET_CART_VERIFYING', false)
  },
  async verifyUnits({ commit }, { replacementGroupId }) {
    const response = await replacementGroupsApi.verify_units.create(
      replacementGroupId
    )

    commit('SET_CART_OPTIMIZATION_GROUP_ITEMS', response.data)

    return response.data
  },
  async verifyItemUnits({ commit }, { variantId, isAi, unitType }) {
    const response = await itemsApi.verify_units.create(
      variantId,
      isAi,
      unitType
    )

    commit('currentUser/SET_CURRENT_USER_CART_ITEM', response.data, {
      root: true,
    })

    return response.data
  },
  async verifyOptimizationItemUnits({ commit }, { variantId, isAi }) {
    const response = await itemsApi.verify_units.create(variantId, isAi)

    commit('SET_CART_OPTIMIZATION_GROUP_ITEM', response.data)
    return response.data
  },
  async applyOptimization({ commit }) {
    commit('SET_CART_OPTIMIZATION_APPLYING', true)
    const { id } = state.optimization.optimized_master_order
    const response = await cartApi.optimization.master_orders.apply.create(id)
    commit('SET_CART_OPTIMIZATION_APPLYING', false)
    return response.data
  },
  async optimizeCart({ commit, dispatch, getters }) {
    commit('SET_CART_OPTIMIZATION_CREATING', true)
    const params = {
      exclude_variant_ids: getters.cartOptimizationExclusionVariantIds,
      exclude_replacement_group_ids: getters.cartOptimizationExclusionGroupIds,
    }
    const response = await cartApi.optimization.master_orders.create(params)
    commit('SET_CART_OPTIMIZATION_CREATING', false)
    const data = response.data
    commit('SET_OPTIMIZED_MASTER_ORDER', data.master_order)
    commit('SET_BEFORE_OPTIMIZATION_EXCLUSIONS', state.optimization.exclusions)
    await dispatch('getCartOptimization')
    return data
  },
  clearOptimizedMasterOrder({ commit }) {
    commit('SET_OPTIMIZED_MASTER_ORDER', null)
    commit('SET_BEFORE_OPTIMIZATION_EXCLUSIONS', {})
  },
}

export const getters = {
  currentCart(_state, _getters, rootState) {
    return rootState.currentUser.currentUser.currentCart
  },
  defaultDeliverAt() {
    return tomorrow
  },
  isMasterOrderCompleted(state) {
    return state.current_cart.orders.every(
      (subOrder) => subOrder.full_supply_state === 'ordered'
    )
  },
  areAllOrdersCompleted(_state, _getters, rootState) {
    return rootState.currentUser.currentUser.currentCart.orders.every(
      (order) => order.full_supply_state === 'ordered'
    )
  },
  isMasterOrderBlocked(_state, getters) {
    return getters.currentCart.orders.every(
      (subOrder) => subOrder.full_supply_state != 'draft'
    )
  },
  isSubOrderBlocked: () => (order) => {
    return order.full_supply_state != 'draft' || order.is_updating
  },
  cartSubOrder: (state) => (id) => {
    return state.current_cart.orders.find((order) => order.id === id)
  },
  canLineItemBeDeleted: (state) => (id) => {
    return !state.current_cart.applied_cart_templates.some((template) =>
      template.items.some((item) => item.line_item_id === id)
    )
  },
  sortedSubOrders(_state, _getters, rootState) {
    const draftedOrders =
      rootState.currentUser.currentUser.currentCart.orders?.filter(
        (order) => order.full_supply_state === 'draft'
      )
    return orderBy(draftedOrders, ['state'], ['desc'])
  },
  listAllItems: (_store, getters) => (q) =>
    getters.currentCart.orders
      .reduce((result, subOrder) => {
        const items = subOrder.line_items.map((item) => {
          item.supplier_name = subOrder.supplier_name
          item.disabled = subOrder.full_supply_state != 'draft'
          item.state = subOrder.full_supply_state

          return item
        })

        return [...result, ...items]
      }, [])
      .filter((item) => {
        const regexp = new RegExp(`^.*${q || ''}.*$`, 'i')

        return (
          item.name.match(regexp) ||
          item.supplier_name.match(regexp) ||
          item.sku.match(regexp)
        )
      }),
  hasExpiredItem: (_state, getters) => {
    return getters.listAllItems('').some((item) => item.expired)
  },
  hasUpdatingItems: (_state, getters) => {
    return getters.sortedSubOrders.some((order) => order.is_updating)
  },
  countAllItemsQuantity: (_state, getters) => {
    return getters
      .listAllItems('')
      .filter((item) => item.state === 'draft')
      .reduce((acc, item) => {
        return acc + item.quantity
      }, 0)
  },
  getTotalItemsQuantityById: (_state, getters) => (id) => {
    return getters.currentCart.template_items_sum[id] || 0
  },
  hasSyncableItemsErrors: () => (errors) => {
    const errorsToSyncItems = [
      'SELLY_BIT_PRICE_DIFFERS_WARNING',
      'SELLY_BIT_ARTICLE_INVALID_FAILURE',
      'SELLY_BIT_ARTICLE_UNKNOWN_WARNING',
      'SELLY_BIT_ARTICLE_REMOVED_FAILURE',
      'SELLY_BIT_ARTICLE_NOTALLOWED_FAILURE',
    ]
    return errorsToSyncItems.some((error) => errors.includes(error))
  },
  allOrderErrors: () => (order) => {
    const orderErrors = order?.processing_errors
    const lineItemsErrors = order?.line_items?.reduce((acc, lineItem) => {
      return [...acc, ...lineItem.processing_errors]
    }, [])
    return [...new Set([...orderErrors, ...lineItemsErrors])]
  },
  isInvalidDeliveryDate: () => (order) => {
    const orderErrors = order?.processing_errors || []
    return orderErrors.includes('SELLY_BIT_DELIVERY_DATE_INVALID_FAILURE')
  },
  findCartOptimizationGroupByItemVariantId: (state) => (variantId) => {
    return state.optimization.list.find((group) =>
      group.items.some((item) => item.variant_id === variantId)
    )
  },
  hasOrders: (_state, getters) => {
    return getters.sortedSubOrders.length > 0
  },
  cartOptimizationStatistics: (state) => {
    return state.optimization.cart_statistics
  },
  cartOptimizationRule: (state) => {
    return state.optimization.cart_optimization_rule
  },
  cartOptimizationRuleProductGroupsByType: (state) => (ruleType) => {
    if (!state.optimization.cart_optimization_rule) return []

    return state.optimization.cart_optimization_rule.rule_items.flatMap(
      (rule_item) =>
        rule_item.rule_type === ruleType ? rule_item.product_groups : []
    )
  },
  defaultCartOptimizationRuleItems: (state) =>
    state.optimization.optimization_rule_types
      .slice(0, 2)
      .map((item, position) => ({ ...item, position })),
  availableCartOptimizationRuleTypes: (state) => {
    return state.optimization.optimization_rule_types.slice(2)
  },
  isCartOptimized: (state) => {
    return !!state.optimization.optimized_master_order?.id
  },
  cartOptimizationGroupExclusions: (state) => (groupId) => {
    return state.optimization.exclusions[groupId]
  },
  cartBeforeOptimizationGroupExclusions: (state) => (groupId) => {
    return state.optimization.before_optimization_exclusions[groupId]
  },
  cartOptimizationExclusionVariantIds: (state) => {
    return flatten(
      values(state.optimization.exclusions).filter((value) => value !== 'all')
    )
  },
  cartOptimizationExclusionGroupIds: (state) => {
    return keys(
      pickBy(state.optimization.exclusions, (value) => value === 'all')
    )
  },
}
