import apiClient from '@/services/api'

export default {
  additives: {
    index(params) {
      return apiClient.get('api/settings/additives', { params })
    },
  },
  allergens: {
    index(params) {
      return apiClient.get('api/settings/allergens', { params })
    },
  },
  base_unit_types: {
    index(params) {
      return apiClient.get('api/settings/base_unit_types', { params })
    },
  },
  bls_replacement_groups: {
    index(params) {
      return apiClient.get('api/lookups/replacement_groups', {
        params: { ...params, group_type: 'bls' },
      })
    },
  },
  item_types: {
    index() {
      return apiClient.get('api/lookups/item_types')
    },
  },
  packaging_types: {
    index(params) {
      return apiClient.get('api/settings/packaging_types', { params })
    },
  },
  product_groups: {
    index(params) {
      return apiClient.get('api/lookups/product_groups', { params })
    },
  },
  replacement_groups: {
    index(params) {
      return apiClient.get('api/lookups/replacement_groups', {
        params: { ...params, group_type: 'custom' },
      })
    },
  },
  supplier_tenants: {
    index(params) {
      return apiClient.get('api/lookups/supplier_tenants', { params })
    },
  },
  tax_categories: {
    index(params) {
      return apiClient.get('api/lookups/tax_categories', { params })
    },
  },
  users: {
    index(params) {
      return apiClient.get('api/lookups/users', { params })
    },
  },
}
