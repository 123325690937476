import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/items', { params })
  },
  create(params) {
    return apiClient.post('api/items', params)
  },
  update(id, params) {
    return apiClient.put(`api/items/${id}`, params)
  },
  show(id) {
    return apiClient.get(`api/items/${id}`)
  },
  offers: {
    index(id) {
      return apiClient.get(`api/items/${id}/offers`)
    },
  },
  pdf: {
    index(params) {
      return apiClient.get('api/items.pdf', { params })
    },
  },
  prices: {
    create(id, params) {
      return apiClient.post(`api/items/${id}/prices`, params)
    },
  },
  versions: {
    index(id, params) {
      return apiClient.get(`api/items/${id}/versions`, { params })
    },
  },
  verify_units: {
    create(itemId, isAi = false, unitType) {
      return apiClient.post(`api/items/${itemId}/verify_units`, {
        is_ai: isAi,
        unit_type: unitType,
      })
    },
    update(itemId, data) {
      return apiClient.put(`api/items/${itemId}/verify_units`, data)
    },
  },
}
