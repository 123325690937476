import dayjs from 'dayjs'
import expiringPricesApi from '@/services/api/expiring-prices'
import Inputmask from 'inputmask'
import Vue from 'vue'
import { isEmpty } from 'lodash'

export const namespaced = true

export const state = {
  count: 0,
  current_page: 0,
  infiniteId: +new Date(),
  q: null,
  items_q: '',
  filtered_items: [],
  selectedItems: [],
  filters: {
    value: null,
    options: [],
  },
  selectedSupplier: null,
  sort: {
    value: {
      name: 'created_at_asc',
      attr: 'created_at',
    },
    options: [
      {
        name: 'created_at_asc',
        attr: 'created_at',
      },
      {
        name: 'created_at_desc',
        attr: '-created_at',
      },
    ],
  },
  list: [],
  update_params: [],
  grouped_list: {},
  pages: 0,
  per_page: 0,
  total_count: 0,
  isBlankState: null,
}

export const mutations = {
  ADD_EXPIRING_PRICES(state, { data, noFilters }) {
    state.list = []
    state.current_page += 1
    state.total_count = data.total_count
    state.isBlankState = noFilters && data.total_count === 0

    if (!data.grouped_suppliers) return

    Object.entries(data.grouped_suppliers).forEach(([date, params]) => {
      if (state.grouped_list[date]) {
        state.grouped_list[date].items_count += params.items_count

        params.suppliers.forEach((supplier) => {
          const foundSupplier = state.grouped_list[date].suppliers.find(
            (existingSupplier) => existingSupplier.id === supplier.id
          )

          if (foundSupplier) {
            foundSupplier.items.push(...supplier.items)
            foundSupplier.items_count = foundSupplier.items.length
          } else {
            state.grouped_list[date].suppliers.push(supplier)
          }
        })
      } else {
        Vue.set(state.grouped_list, date, params)
      }
    })
  },
  ADD_ITEM_TO_UPDATE_PARAMS(state, item) {
    const params = {
      id: item.id,
      unavailable_on: item.unavailable_on,
      price: item.price,
    }

    const existingItemParams = state.update_params.find(
      (itemParams) => itemParams.id === params.id
    )

    if (existingItemParams) {
      existingItemParams.id = params.id
      existingItemParams.unavailable_on = params.unavailable_on
      existingItemParams.price = params.price
    } else {
      state.update_params.push(params)
    }
  },
  CLEAR_SUPPLIERS(state) {
    state.grouped_list = {}
    state.current_page = 0
    state.infiniteId += 1
  },
  FILTER_ITEMS_IN_SUPPLIER(state) {
    state.filtered_items = state.selectedSupplier.items.filter((item) => {
      return (
        item.name.toLowerCase().includes(state.items_q.toLowerCase()) ||
        item.sku.toLowerCase().includes(state.items_q.toLowerCase())
      )
    })
  },
  REMOVE_VALID_ITEMS(state, ids) {
    state.total_count -= ids.length
    Object.entries(state.grouped_list).forEach(([, params]) => {
      params.items_count -= ids.length
      params.suppliers.forEach((supplier) => {
        supplier.items = supplier.items.filter((item) => !ids.includes(item.id))
        supplier.items_count = supplier.items.length
      })
    })
  },
  SELECT_SUPPLIER(state, supplier) {
    state.selectedSupplier = supplier
  },
  SELECTED_ITEMS(state, item) {
    if (state.selectedItems.some((itm) => itm.id === item.id)) {
      state.selectedItems = state.selectedItems.filter(
        (itm) => itm.id !== item.id
      )
    } else {
      state.selectedItems.push(item)
    }
  },
  UPDATE_EXPIRING_ITEM_LIST(state, items) {
    state.selectedSupplier.items = state.selectedSupplier.items
      .map((item) => {
        let currentItem = items.find(
          (itm) => item.variant_id === itm.variant_id
        )
        return currentItem ? currentItem : item
      })
      .filter((item) => dayjs(item.unavailable_on) <= dayjs())

    state.selectedSupplier.items_count = state.selectedSupplier.items.length
  },
}

export const actions = {
  changeItemUnavailableOn({ commit }, { item, date }) {
    item.unavailable_on = date

    commit('ADD_ITEM_TO_UPDATE_PARAMS', item)
  },
  changeItemPrice({ commit }, { item, price }) {
    item.price = price

    commit('ADD_ITEM_TO_UPDATE_PARAMS', item)
  },
  async filterSuppliers({ commit }) {
    commit('CLEAR_SUPPLIERS')
  },
  async getExpiringPrices({ state, commit }, infiniteLoaderContext) {
    const { current_page } = state
    const startInfiniteId = state.infiniteId

    try {
      const response = await expiringPricesApi.index({
        q: state.q,
        page: current_page + 1,
        sort: state.sort.value && state.sort.value.attr,
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_EXPIRING_PRICES', {
        data: response.data,
        noFilters: isEmpty(state.q),
      })
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  async saveItems({ state, commit }) {
    const response = await expiringPricesApi.item_list.update({
      items: state.update_params.map((params) => {
        return {
          ...params,
          price: Inputmask.unmask(params.price, {
            alias: 'currency',
            suffix: '€',
            radixPoint: '.',
            inputType: 'number',
          }),
        }
      }),
    })

    commit('UPDATE_EXPIRING_ITEM_LIST', response.data.items)
    commit('CLEAR_SUPPLIERS')

    return state.selectedSupplier.items
  },
  searchItemsInSupplier({ commit }) {
    commit('FILTER_ITEMS_IN_SUPPLIER')
  },
  async selectSupplier({ commit }, supplier) {
    commit('SELECT_SUPPLIER', supplier)
  },
  selectItem({ commit }, item) {
    commit('SELECTED_ITEMS', item)
  },
}

export const getters = {
  filteredItemsInSupplier(state) {
    if (state.items_filter_enabled) {
      return state.selectedSupplier.items.filter((item) => {
        return (
          item.name.toLowerCase().includes(state.items_q.toLowerCase()) ||
          item.sku.toLowerCase().includes(state.items_q.toLowerCase())
        )
      })
    } else {
      return state.selectedSupplier.items
    }
  },
  isItemSelected: (state) => (item) => {
    return state.selectedItems.some((itm) => itm.id === item.id)
  },
}
