import cartApi from '@/services/api/cart'
import itemsApi from '@/services/api/items'
import userPreferencesApi from '@/services/api/user-preferences'
import { viewTypeToIconName } from '@/services/helpers'

export const namespaced = true

export const state = {
  loading: false,
  resource: null,
  touched: false,
  view_type: 'full-view-icon_24px',
  q: null,
  filters: {
    sort: {
      value: { name: 'supplier_asc', type: 'sort', attr: 'suppliers.name' },
      options: [
        { name: 'supplier_asc', type: 'sort', attr: 'suppliers.name' },
        { name: 'supplier_desc', type: 'sort', attr: '-suppliers.name' },
      ],
      type: 'radio',
      selected: false,
    },
  },
  sort: {
    value: { name: 'supplier_asc', attr: 'suppliers.name' },
    options: [
      { name: 'supplier_asc', attr: 'suppliers.name' },
      { name: 'supplier_desc', attr: '-suppliers.name' },
    ],
  },
}

export const mutations = {
  ADD_NEW_CART_TEMPLATE_ITEM(state, item) {
    const newItem = Object.assign(
      { spree_price_id: item.id, quantity: 1, draft: true },
      item
    )
    state.resource.items.unshift(newItem)
    state.touched = true
  },
  APPLY_SUPPLIER_FILTER(state, { value }) {
    state.filters.sort.value = value
    state.sort.value = value
  },
  DELETE_CART_TEMPLATE_ITEM(state, { id }) {
    state.resource.items = state.resource.items.filter((item) => {
      return item.id !== id
    })
  },
  REPLACE_CART_TEMPLATE_ITEM_AMOUNT(state, { id, quantity, invalid }) {
    state.resource.items.forEach((item) => {
      if (item.id === id) {
        item.quantity = quantity < 0 ? -1 : quantity
      }
    })
    state.resource.invalid = invalid
    state.touched = true
  },
  SET_ORDER_TEMPLATE(state, { order_template, view_type }) {
    state.resource = order_template
    state.view_type = viewTypeToIconName(view_type)
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_SORT_VALUE(state, value) {
    state.sort.value = value
  },
  SET_VIEW_TYPE(state, value) {
    state.view_type = value
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
  UPDATE_ITEM(state, { item }) {
    state.resource.items = state.resource.items.map((oldItem) => {
      if (oldItem.variant_id !== item.variant_id) return oldItem

      return { ...oldItem, ...item }
    })
  },
  UPDATE_ORDER_TEMPLATE(state, { order_template }) {
    state.resource = { ...state.resource, ...order_template }
  },
}

export const actions = {
  addNewCartTemplateItem({ commit }, item) {
    commit('ADD_NEW_CART_TEMPLATE_ITEM', item)
  },
  async applySupplierFilter({ state, commit }, { type, value }) {
    commit('APPLY_SUPPLIER_FILTER', { type, value })
    commit('SET_LOADING', true)

    const response = await cartApi.templates.show(state.resource.id, {
      sort: value.attr,
    })

    commit('SET_ORDER_TEMPLATE', response.data)
    commit('SET_LOADING', false)
  },
  changeCartTemplateItemQuantity({ commit }, params) {
    commit('REPLACE_CART_TEMPLATE_ITEM_AMOUNT', params)
  },
  async updateCartTemplateSpreePrice({ commit }, { id, ...params }) {
    const response = await cartApi.templates.order_template_spree_prices.update(
      id,
      params
    )

    commit('REPLACE_CART_TEMPLATE_ITEM_AMOUNT', response.data)
  },
  async deleteCartTemplateItem({ commit }, { id, ...params }) {
    const response = await cartApi.templates.order_template_spree_prices.delete(
      id,
      params
    )

    commit('DELETE_CART_TEMPLATE_ITEM', response.data)
  },
  excludeCartTemplateItem({ commit }, item) {
    commit('DELETE_CART_TEMPLATE_ITEM', item)
  },
  async getOrderTemplate({ state, commit }, { id, optimizationType }) {
    const { sort } = state
    commit('SET_LOADING', true)
    const response = await cartApi.templates.show(id, {
      sort: sort.value.attr,
      optimization_type: optimizationType,
    })

    commit('SET_ORDER_TEMPLATE', response.data)
    commit('SET_LOADING', false)
    return response
  },
  async getOrderTemplateShort({ state, commit }, { id, optimizationType }) {
    const {
      resource: { items },
    } = state
    const response = await cartApi.templates.short.show(id, {
      optimization_type: optimizationType,
      draft_item_ids: items
        .filter(({ draft }) => draft)
        .map(({ spree_price_id }) => spree_price_id),
    })

    commit('UPDATE_ORDER_TEMPLATE', response.data)
    return response
  },
  setOrderTemplate({ commit }, { order_template, view_type }) {
    commit('SET_ORDER_TEMPLATE', { order_template, view_type })
  },
  async sortTemplateItemsBySupplier({ state, commit }, value) {
    commit('SET_SORT_VALUE', value)
    commit('SET_LOADING', true)
    const response = await cartApi.templates.show(state.resource.id, {
      sort: value.attr,
    })

    commit('SET_ORDER_TEMPLATE', response.data)
    commit('SET_LOADING', false)
  },
  toggleSupplierFilterItems({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
  async updateCartTemplate({ commit }, { id, ...params }) {
    const response = await cartApi.templates.update(id, params)

    commit('SET_ORDER_TEMPLATE', response.data)
    return response
  },
  async updateViewType({ commit }, value) {
    const mappings = {
      'full-view-icon_24px': 'template_items_full',
      'card-view-icon_24px': 'template_items_card',
      actions_list_view: 'template_items_list',
    }

    await userPreferencesApi.update({
      order_template_items_view_type: mappings[value],
    })

    commit('SET_VIEW_TYPE', value)
  },
  async updateDraftItemPrice({ commit }, { id, price }) {
    const response = await itemsApi.prices.create(id, { price })

    commit('UPDATE_ITEM', {
      item: { ...response.data, spree_price_id: response.data.id },
    })

    return response.data
  },
  async updateTemplateItemPrice({ commit }, { id, price }) {
    const response = await cartApi.templates.order_template_spree_prices.update(
      id,
      { price }
    )

    commit('UPDATE_ITEM', { item: response.data })

    return response.data
  },
  async verifyItemUnits({ commit }, { variantId }) {
    const response = await itemsApi.verify_units.create(variantId)

    commit('UPDATE_ITEM', response.data)

    return response.data
  },
}

export const getters = {
  supplierFilterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
  templateItemByPriceItem: (state) => (item) => {
    return state.resource.items.find(
      (existingItem) => existingItem.spree_price_id === item.id
    )
  },
  qtyInTemplate: (_, getters) => (item) => {
    const templateItem = getters.templateItemByPriceItem(item)

    return templateItem ? templateItem.quantity : 0
  },
  itemListParams: (state) => {
    return state.resource.items.map((item) => {
      return { quantity: item.quantity, spree_price_id: item.spree_price_id }
    })
  },
  itemQtyInTemplate: (_, getters) => (item) => {
    const templateItem = getters.templateItemByPriceItem(item)

    return templateItem && templateItem.quantity >= 0
      ? templateItem.quantity
      : -1
  },
}
